<template>
  <div
    class="page-sso"
  >
    <div
      class="page__info"
      v-if="loginFailed"
    >
      <div class="page__title">
        {{ $t('sso.loginFail') }}
      </div>
      <div class="page__content">
        {{ $t('sso.searchFail') }}
      </div>
      <img
        src="@/assets/images/logo.svg"
        alt="logo"
        class="page__img"
      >
    </div>
    <spinner
      v-else
      class="page__spinner"
    />
  </div>
</template>
<script>
import { mapActions } from 'vuex'
import { defineComponent } from '@vue/composition-api'
import { externalCustomerLogin } from '@/API/SSO'

export default defineComponent({
  name: 'SSO',
  data () {
    return {
      loginFailed: false
    }
  },
  async created () {
    const { client_id = null } = this.$route.params
    if (!client_id) {
      this.loginFailed = true
      return
    }

    try {
      const { jwtToken = null, location = null } = await externalCustomerLogin({
        ssoSettingId: client_id,
        ...this.$route.query
      })
      if (jwtToken) {
        localStorage.setItem('token', jwtToken)
        await this.getUserInfo()
        this.$router.push(location || '/')
      } else {
        this.loginFailed = true
      }
    } catch (err) {
      this.loginFailed = true
      console.log(err)
    }
  },
  methods: {
    ...mapActions('userManagement', ['getUserInfo'])
  }
})
</script>
<style lang="scss" scoped>
.page-sso {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .page__info {
    text-align: center;
  }
  .page__title {
    font-size: 32px;
    margin-bottom: 32px;
  }
  .page__content {
    margin-bottom: 32px;
  }
  .page__img {
    width: 80px;
  }
  .page__spinner {
    height: 100vh;
  }
}
</style>
